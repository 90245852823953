
/*! `Custom` Bootstrap 4 theme */
@import url(https://use.fontawesome.com/releases/v5.0.10/css/all.css);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:200,300,400,700);
$headings-font-family:Open Sans;
$font-family-base:Open Sans;
$enable-grid-classes:true;
$primary:#f7941e !default;
$secondary:#868e96 !default;
$success:#28a745 !default;
$danger:#dc3545 !default;
$info:#17a2b8 !default;
$warning:#F4976C !default;
$light:#fff !default;
$dark:#f7941e !default;

$modal-header-border-color: $warning;

$rfs-base-font-size: 0.8rem !default;
//dropdowns
$dropdown-bg:#fff !default;
$dropdown-link-color:#f7941e !default;
$dropdown-link-hover-bg:$light;

//Modals
$modal-backdrop-bg:  #fff !default;

$enable-gradients:false;
$spacer:1rem;
$border-width:1px;

//Buttons
$btn-border-radius:.25rem;

//Navbars
$navbar-dark-color:#000;
$navbar-nav-link-padding-x:1.5rem !default;
.navbar .nav-item{
padding-left:1.5rem; padding-right:1.5rem;}
